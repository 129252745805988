import React from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

class WRegisterForm extends React.Component {
    state = {
        message: null,
        error: false,
        form:{

        }
    }

    setForm = (elemId) => (e) => {
        this.state.form[elemId] = e.target.value;
        this.setState({form: this.state.form});
    }

    render(){
        return (
            <div className="login-container">
                <div className="paper">
                    <Grid container spacing={24}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>

                            <div className="login-section">
                             
                                <form className="container" noValidate autoComplete="off">
                                <FormControl className="form-control">
                                    <Typography variant="h6" color="inherit">
                                        Sign up
                                    </Typography>
                                    <TextField
                                        autoFocus
                                        id="email"
                                        label="email"
                                        type="email"
                                        className="text-field"
                                        margin="dense"
                                        value={this.state.form.email || ""}
                                        onChange={this.setForm("email")}
                                        fullWidth
                                    />
                                    <Typography variant="h6" color="inherit"/>
                                    <TextField
                                        autoFocus
                                        id="password"
                                        label="password"
                                        type="password"
                                        className="text-field"
                                        margin="dense"
                                        value={this.state.form.password || ""}
                                        onChange={this.setForm("password")}
                                        fullWidth
                                    />
                                    <Typography variant="h6" color="inherit"/>
                                    <TextField
                                        autoFocus
                                        id="password2"
                                        defaultValue=""
                                        label="re-type"
                                        type="password"
                                        className="text-field"
                                        margin="dense"
                                        value={this.state.form.password2 || ""}
                                        onChange={this.setForm("password2")}
                                        fullWidth
                                    />
                                    <Typography variant="h6" color="inherit"/>
                                    <div className="form-control opaque">
                                        <Typography variant="body2" color="inherit"> 
                                            You must be at least 14 years old to use this service. We are not responsible for anything.
                                            E = MC^2. 
                                        </Typography> 
                                        <Typography variant="caption" color="inherit"> 
                                            Also your credentials won't be stored here, Google will do this for us. 
                                            It would be a good idea to not use anything sensitive anyway. 
                                        </Typography> 
                                        <Typography variant="h6" color="inherit"/>
                                        <div className="form-control">
                                            <a className="button" role="button" onClick={this.register}>
                                                Submit
                                            </a>
                                        </div>
                                    </div>
                                    <div className="form-control">
                                        <Typography variant="body2" color="inherit">
                                            {
                                            this.state.message && (<FormHelperText error={this.state.error}>{this.state.message}</FormHelperText>)
                                            }
                                        </Typography>
                                    </div>
                                </FormControl>
                                </form>
                            </div>
                            {/*
                                $r("div", {style:{ fontSize:"0.8em", bottom: "0px", paddingBottom: "10px", paddingLeft:"10px", position:"absolute"}},
                                    <Link href="/ui/login/">Log In?</Link> )
                            */}
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    
                </div>
            </div>
        );
    }

    register = async () => {
        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;
        var password2 = document.getElementById("password2").value;

        if (password !== password2){
            this.setState({message: "Passwords should match", error: true});
            return;
        }

        var response = await fetch('/user/register/', {
            method: 'post',
            body: JSON.stringify({email, password}),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        var data = await response.json();
        if (data.error){
            //alert(data);
            this.setState({message: data.error, error: true})
        } else {
            //mixpanel.track("action-register");
            this.setState({message: "Registration successful, please check your mail", error:false});
            document.location = "/ui/";
        } 
    };
};


class WRegisterTab extends React.Component {
    componentWillMount() { 

    }

    //prohibit login page from being displayed if user have an active session
    componentDidUpdate(){
        var store = this.props.store;
        if (store.user){
            this.props.router.navigate("/web");
        }
    }

    render(){
        return (
            <WRegisterForm/>
        );
    }
};

export {
    WRegisterForm,
    WRegisterTab
}
export default WRegisterTab;