import React from "react";
import { Typography, Grid } from '@material-ui/core';

import {DS} from "@common/store.js";

class WUserProfileTab extends React.Component {

    state = {
        form:{

        }
    }

    componentDidMount(){
        new DS("/kgnet/save/").bind(CCMStore, "saves");
    }

    setForm = (elemId) => (e) => {
        this.state.form[elemId] = e.target.value;
        this.setState({form: this.state.form});
    }

    render() {
        const {store} = this.props;
        return (
            <div className="paper">
                <Grid container spacing={24}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                        <form className="container" noValidate autoComplete="off">
                            <Typography variant="h6" color="inherit">
                                Profile
                            </Typography>
                            <div className="form-control">
                                <Typography variant="subtitle1" color="inherit">
                                    Cloud saves:
                                </Typography>
                                {store.saves && (
                                    <table>
                                        <caption>Cloud saves</caption>
                                        <thead>
                                                <tr>
                                                    <th>Save data</th>
                                                    <th>Last update</th>
                                                    <th>Size</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                store.saves.map(save => {
                                                    return (
                                                        <tr key={save.guid}>
                                                            <td>
                                                                {save.index ? 
                                                                (
                                                                    "Year "+ save.index.calendar.year + ", day " + save.index.calendar.day
                                                                ) : (
                                                                    <a href={"/kgnet/save/" + save.guid + "/parse/"} target="_blank">Update</a>
                                                                )}
                                                            </td>
                                                            <td>{save.timestamp}</td>
                                                            <td>{save.size}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>)
                                }
                            </div>
                        </form>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
           </div>
        )
    }

    updateIndex = () => {
       //when server responded with parsed save, we can patch record in the collection
       //without reloading the whole data
    }
   
};

export default WUserProfileTab;